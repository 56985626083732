import { DEV } from './constants'

export const Project = {
  api: DEV ? process.env.API_URL : 'https://staging-backend.justfix.app/',
  apiAuth: '',
  appleUrl:
    'https://apps.apple.com/gb/app/justfix-local-tradespeople/id1532362978',
  appsFlyer: {
    appId: 'id1532362978',
    devKey: 'Ez7Ysp5EqF9gENkXg9A5jF',
  },
  cancellationPolicy: 'https://www.justfix.app/cancellation-policy',
  climatePositiveUrl: 'https://ecologi.com/justfix',
  codepush: {
    android: {
      production: 'sjYGHVDrOhbQmR6EW5P_kirR0jm6bdAPamyh9',
      staging: 'Z1_qcv5SblriXEawzXKcV5SKBf5Pp1ich1-tk',
    },
    ios: {
      production: '1DA1xpPbZxYiau6yjOUMSEhxNj0aioXEFZ6cY',
      staging: 'RInEkbxkehqMg93UpiJoHMu0BAtNUucd45Gm0',
    },
  },
  cookiePolicy: 'https://www.justfix.app/cookie-policy',
  debug: false,
  defaultCancellationFee: 45.0,
  dynamicLinks: {
    android: {
      packageName: 'com.ssg.boilerplate',
    },
    domainUriPrefix: 'https://ssg.page.link',
    ios: {
      appStoreId: '1532362978',
      bundleId: 'com.justfix.user',
    },
  },
  facebookUrl: 'https://www.facebook.com/JustFixApp',
  formly: 'boilerplate',
  ga: '',
  googleMapApi: 'AIzaSyBCLlYO0bMga5dTgAHZ90hzx9-ACmo2E9o',
  googleMapGeocode: 'AIzaSyDQv6RnMC25Q9aHVOoGWhL1HT7lqb71_OQ',
  googleUrl: 'https://play.google.com/store/apps/details?id=com.justfix.user',
  grecaptcher: '6Lc1mf4UAAAAAC6uZ0HaGJ3ufgwCXY4GFoNhMeBD',
  gtmId: 'GTM-KLTWVH88',
  instagramUrl: 'https://www.instagram.com/justfix_app/',
  investorsUrl: 'https://www.justfix.app/investor-registration-2023',
  logs: {
    API: false,
    DATA: false,
    DISPATCHER: false,
    EVENTS: true,
    PUSH_NOTIFICATIONS: false,
    SERVER: false,
    STORAGE: false,
    STORE: false,
  },
  mixpanel: 'fc3b4bccba1f6d883f8f6c6c767baf13',
  privacyPolicy: 'https://www.justfix.app/privacy-policy',
  proxy: '/api/',
  recentNewsUrl: 'https://www.justfix.app/recent-news',
  referrer: 'https://staging-my.justfix.app/',
  sentryEnvironment: 'development',
  stripe: {
    key: 'pk_test_51Kw6ZaHSfKj5MSCkZUnAGyWnCz1c0t8dGheKX39rmJ0i4T1OlfW1PEJMOqNPGKPXILMSTK1Go227OiDRUCNWhJ2800YFcTJH99',
    merchantIdentifier: 'merchant.com.justfix.user',
  },
  supportPolicy: 'https://www.justfix.app/support-policy',
  termsAndConditions: 'https://www.justfix.app/terms-and-conditions',
  twitterUrl: 'https://twitter.com/justfixapp',
  userAgent: 'com.justfix.user',
  whoops: 'https://www.justfix.app/whoops',
  youtubeUrl: 'https://www.youtube.com/channel/UChWejGdNwf6V27kB6-K6TFg',
}
